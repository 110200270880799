<template>
  <LayoutHome>
    <!-- display1 -->
    <div class="course-wrapper" v-if="display1List.length > 0">
      <h3 class="main-title">{{display1Lalbel}}</h3>
      <el-row :gutter="8">
        <el-col v-for="(item,index) in display1List" :key="index" :span="6">
          <el-card shadow="never" class="course-card" :body-style="{ padding: '0px' }" style="margin-top: 20px">
            <div @click="toCourseDetail(item.id,item.catalog)">
              <img :src="item.coverAttachmentUrl || `/images/cover/${item.catalog}.png`" class="image"/>
              <div style="padding: 14px">
                <el-tooltip effect="dark" :content="item.courseName" placement="top-start">
                  <span class="tit">{{item.courseName || '**'}}</span>
                </el-tooltip>
                <div class="bottom clearfix">
                  <span class="desc">{{item.catalogDescription}}</span>
                  <div class="study">
                    <span class="point" :style="{color:themeColor}">{{item.hours}}</span>
                    <span style="">学时</span>
                    <span class="point" :style="{color:themeColor}">{{item.credit}}</span>
                    <span style="">学分</span>
                    <span style="float: right">
                      <span>已选学生</span>
                      <span class="point" :style="{color:themeColor}">{{studiesDesc(item.studies)}}</span>
                      <span>人</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="read-more">
        <el-button type="text" @click="toPage('first-class-course')"  :style="{color:themeColor}">查看更多<i class="el-icon-arrow-right"></i></el-button>
      </div>
    </div>
    <!-- display2 -->
    <div class="course-show-wrapper" :style="{backgroundImage:`url(${indexData.background2AttachmentUrl} )`}">
      <div class="course-show" v-if="display2List.length > 0">
        <div class="mol-thr">{{display2Lalbel}}</div>
        <el-row :gutter="8" style="margin-top: 20px">
          <el-col v-for="(item,index) in display2List" :key="index" :span="6">
            <div class="course-info-wrapper">
              <img :src="item.coverAttachmentUrl || `/images/cover/${item.catalog}.png`" style="width: 100%;height:200px;" @click="toCourseDetail(item.id,item.catalog)" />
              <div class="course-info">
                <el-tooltip effect="dark" :content="item.courseName" placement="top-start">
                  <span class="info-tit">{{item.courseName}}</span>
                </el-tooltip>
                <div>
                  <el-tooltip effect="dark" :content="(item.introduce || '').replace(/<\/?[^>]*>/g, '')" placement="top-start">
                    <div class="info-desc">{{(item.introduce || '').replace(/<\/?[^>]*>/g, '')}}</div>
                  </el-tooltip>
                  <div class="info-img">
                    <el-avatar size="small" src="https://image.zhihuishu.com/zhs/createcourse/course/201711/1bf57ff2f10e4c30b811f2f556f10170_s3.jpg"></el-avatar>
                    <span class="teacher">{{item.manager}}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="course-more">
          <el-button type="text" style="color: #fff" @click="toPage('declaration-course')">查看更多<i class="el-icon-arrow-right"></i></el-button>
        </div>
      </div>
    </div>

    <!-- 一流课程 -->
    <!--
    <div class="course-wrapper" v-if="showDisplayUndergraduate">
      <h3 class="main-title">{{displayUndergraduate.name}}</h3>
      <el-row :gutter="8">
        <el-col v-for="(item,index) in undergraduateList" :key="index" :span="6">
          <el-card shadow="never" class="course-card" :body-style="{ padding: '0px' }" style="margin-top: 20px">
            <div @click="toCourseDetail(item.id,item.catalog)">
              <img :src="item.coverAttachmentUrl || `/images/cover/${item.catalog}.png`" class="image"/>
              <div style="padding: 14px">
                <span class="tit">{{item.courseName || '**'}}</span>
                <div class="bottom clearfix">
                  <span class="desc">{{item.catalogDescription}}</span>
                  <div class="study">
                    <span class="point" :style="{color:themeColor}">{{item.hours}}</span>
                    <span style="">学时</span>
                    <span class="point" :style="{color:themeColor}">{{item.credit}}</span>
                    <span style="">学分</span>
                    <span style="float: right">
                      <span>已选学生</span>
                      <span class="point" :style="{color:themeColor}">{{studiesDesc(item.studies)}}</span>
                      <span>人</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="read-more">
        <el-button type="text" @click="toPage('first-class-course')"  :style="{color:themeColor}">查看更多<i class="el-icon-arrow-right"></i></el-button>
      </div>
    </div>
    -->
    <!-- 申报课程 -->
    <!--
    <div class="course-show-wrapper" :style="{backgroundImage:`url(${indexData.background2AttachmentUrl} )`}">
      <div class="course-show" v-if="showDisplayDeclaration">
        <div class="mol-thr">{{displayDeclaration.name}}</div>
        <el-row :gutter="8" style="margin-top: 20px">
          <el-col v-for="(item,index) in declarationList" :key="index" :span="6">
            <div class="course-info-wrapper">
              <img :src="item.coverAttachmentUrl || `/images/cover/${item.catalog}.png`" style="width: 100%;height:200px;" @click="toCourseDetail(item.id,item.catalog)" />
              <div class="course-info">
                <span class="info-tit">{{item.courseName}}</span>
                <div>
                  <div class="info-desc">{{(item.introduce || '').replace(/<\/?[^>]*>/g, "")}}</div>
                  <div class="info-img">
                    <el-avatar size="small" src="https://image.zhihuishu.com/zhs/createcourse/course/201711/1bf57ff2f10e4c30b811f2f556f10170_s3.jpg"></el-avatar>
                    <span class="teacher">{{item.manager}}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="course-more">
          <el-button type="text" style="color: #fff" @click="toPage('declaration-course')">查看更多<i class="el-icon-arrow-right"></i></el-button>
        </div>
      </div>
    </div>
    -->

    <!-- 其他课程1 -->
    <!--
    <div class="course-show-wrapper" :style="{backgroundImage:`url (${indexData.background2AttachmentUrl} )`}" v-if="others1List.length > 0">
      <div class="course-show">
        <div class="mol-thr">{{displayOthers1.name}}</div>
        <div class="mol-thr-f">ONLINE OPEN COURSE</div>
        <el-row :gutter="8" style="margin-top: 20px">
          <el-col v-for="(item,index) in others1List" :key="index" :span="6">
            <div class="course-info-wrapper">
              <img :src="item.coverAttachmentUrl || `/images/cover/${item.catalog}.png`" style="width: 100%"/>
              <div class="course-info">
                <span class="info-tit">{{item.courseName}}</span>
                <div>
                  <div class="info-desc">{{item.introduce}}</div>
                  <div class="info-img">
                    <el-avatar size="small" src="https://image.zhihuishu.com/zhs/createcourse/course/201711/1bf57ff2f10e4c30b811f2f556f10170_s3.jpg"></el-avatar>
                    <span class="teacher">{{item.manager}}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="course-more">
          <el-button type="text" style="color: #fff" @click="toPage('course')">查看更多<i class="el-icon-arrow-right"></i></el-button>
        </div>
      </div>
    </div>
    -->
    <!-- 其他课程2 -->
    <!--
    <div class="course-show-wrapper" :style="{backgroundImage:`url (${indexData.background2AttachmentUrl} )`}" v-if="others2List.length > 0">
      <div class="course-show">
        <div class="mol-thr">{{displayOthers2.name}}</div>
        <div class="mol-thr-f">ONLINE OPEN COURSE</div>
        <el-row :gutter="8" style="margin-top: 20px">
          <el-col v-for="(item,index) in others2List" :key="index" :span="6">
            <div class="course-info-wrapper">
              <img :src="item.coverAttachmentUrl || `/images/cover/${item.catalog}.png`" style="width: 100%"/>
              <div class="course-info">
                <span class="info-tit">{{item.courseName}}</span>
                <div>
                  <div class="info-desc">{{item.introduce}}</div>
                  <div class="info-img">
                    <el-avatar size="small" src="https://image.zhihuishu.com/zhs/createcourse/course/201711/1bf57ff2f10e4c30b811f2f556f10170_s3.jpg"></el-avatar>
                    <span class="teacher">{{item.manager}}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="course-more">
          <el-button type="text" style="color: #fff" @click="toPage('course')">查看更多<i class="el-icon-arrow-right"></i></el-button>
        </div>
      </div>
    </div>
    -->

    <!-- 新闻动态 政策文件 -->
    <div class="news-wrapper">
      <el-col :span="17" v-if="showNews">
        <div class="news-title">
          <b>{{displayNews.name}}</b>
          <el-button type="text" class="more-list" @click="toPage('news')" :style="{color:themeColor}">查看更多<i class="el-icon-arrow-right"></i></el-button>
        </div>
        <div v-for="(item,index) in newsList" :key="index" class="news-items">
          <div class="clearfix news-img"  @click="toDetail(item.id)">
            <img :src="item.coverAttachmentUrl ? item.coverAttachmentUrl : ''" class="image" >
          </div>
          <div class="news-info"  @click="toDetail(item.id)">
          <div>
            <el-tooltip effect="dark" :content="item.title" placement="top-start">
              <div class="news-tit">{{item.title}}</div>
            </el-tooltip>
            <el-tooltip effect="dark" :content="(item.content || '').replace(/<\/?[^>]*>/g, '')" placement="top-start">
              <div class="news-desc">{{(item.content || '').replace(/<\/?[^>]*>/g, '')}}</div> 
            </el-tooltip>
          </div>
            <div class="news-time">{{handleTime(item.publishTime, 'YYYY/MM/DD')}}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6" v-if="showPolicy">
        <div class="policy-wrap">
          <span class="policy-title">{{displayPolicy.name}}</span>
          <el-button type="text" class="more-list" @click="toPage('policy')" :style="{color:themeColor}">查看更多<i class="el-icon-arrow-right"></i></el-button>
        </div>
        <div v-for="(item,index) in policyList" :key="index" class="policy-item">
          <el-tooltip effect="dark" :content="item.title" placement="top-start">
            <div class="policy-tit">{{item.title}}</div>
          </el-tooltip>
          <div class="policy-inf">
            <span class="time">{{handleTime(item.publishTime, 'YYYY/MM/DD')}}</span>
            <a class="download-href" :href="item.file1AttachmentUrl" :download="item.file1AttachmentName" type="primary" size="mini" :style="{backgroundColor:themeColor,border:themeColor}"><i class="icon iconfont iconxiazai"></i>点击下载</a>
          </div>
        </div>
      </el-col>
    </div>
    
    <div class="introduce-wrapper" :style="{backgroundImage: indexData.background3AttachmentUrl ? `url( ${indexData.background3AttachmentUrl} )` : ''}">
      <div class="introduce">
        <div class="title" :style="{color:themeColor}">{{indexData.title}}</div>
        <el-tooltip effect="dark" :content="indexData.introduction" placement="top-start">
          <div class="text">{{indexData.introduction}}</div>
        </el-tooltip>
        <el-button type="primary" @click="toWeb" :style="{backgroundColor:themeColor,border:themeColor}">访问官网</el-button>
      </div>
    </div>
  </LayoutHome>
</template>
<script>
import LayoutHome from "./../../components/layoutHome/LayoutHome";
import Time from "../../plugins/date";
import axios from "axios";
export default {
  components: {
    LayoutHome,
  },
  data() {
    return {
      formInline: {
        pageSize:12,
        pageNum:1
      },
      undergraduateTotal:0,
      undergraduateList:[],
      declarationTotal:0,
      declarationList:[],
      others1Total:0,
      others1List:[],
      others2Total:0,
      others2List:[],
      newsTotal:0,
      newsList: [],
      policyTotal: 0,
      policyList: [],
      tmp:'',
      showModules: [],
      indexData:{},
      display1Lalbel: '',
      display1List: [],
      display2Lalbel: '',
      display2List: [],
    };
  },
  computed: {
    themeColor(){
      return this.$store.state.themeColor;
    },
    displayUndergraduate() {
      return this.getDisplay(10);
    },
    displayDeclaration() {
      return this.getDisplay(11);
    },
    displayOthers1() {
      return this.getDisplay(29);
    },
    displayOthers2() {
      return this.getDisplay(30);
    },
    displayPolicy() {
      return this.getDisplay(18);
    },
    displayNews() {
      return this.getDisplay(17);
    },
    displayTeacher() {
      return this.getDisplay(26);
    },
    showIndex() {
      return this.showContent(9);
    },
    showUndergraduate() {
      return this.showContent(10);
    },
    showDeclaration() {
      return this.showContent(11);
    },
    showOthersOnline() {
      return this.showContent(12);
    },
    showOthersMixture() {
      return this.showContent(13);
    },
    showOthersOffline() {
      return this.showContent(14);
    },
    showOthersOffline() {
      return this.showContent(15);
    },
    showOthersOffline() {
      return this.showContent(16);
    },
    showNews() {
      return this.showContent(17);
    },
    showPolicy() {
      return this.showContent(18);
    },
    showDisplayUndergraduate() {
      return this.showContent(19);
    },
    showDisplayDeclaration() {
      return this.showContent(20);
    },
    showDisplayOnline() {
      return this.showContent(21);
    },
    showDisplayMixture() {
      return this.showContent(22);
    },
    showDisplayOffline() {
      return this.showContent(23);
    },
    showDisplayPractice() {
      return this.showContent(24);
    },
    showDisplayExperiment() {
      return this.showContent(25);
    },
    showTeacher() {
      return this.showContent(26);
    },
    showOthers1() {
      return this.showContent(29);
    },
    showOthers2() {
      return this.showContent(30);
    },
  },
  created() {
    this.initModule();
    this.getNewsList();
    this.getPolicyList();
    this.getUndergraduateList();
    this.getDeclarationList();
    this.getOthers1List();
    this.getOthers2List();
    this.getIndexData();
    this.initDisplay();
  },
  methods: {
    async initDisplay() {
      let param1 = {pageSize: 8,pageNum: 1,shoolId: this.indexData.schoolId};
      this.display1Lalbel = this.indexData.displayLabel1;
      switch (this.indexData.display1) {
        case 'da':this.display1List = (await this.$get(`/admin/getUndergraduateCourseDtoPage`, param1)).content;break;
        case 'db':this.display1List = (await this.$get(`/admin/getDeclarationCourseDtoPage`, param1)).content;break;
        case 'd1':this.display1List = (await this.$get(`/admin/getOthers1CourseDtoPage`, param1)).content;break;
        case 'd2':this.display1List = (await this.$get(`/admin/getOthers2CourseDtoPage`, param1)).content;break;
        case 'd3':this.display1List = (await this.$get(`/admin/getOthers3CourseDtoPage`, param1)).content;break;
        case 'd4':this.display1List = (await this.$get(`/admin/getOthers4CourseDtoPage`, param1)).content;break;
        case 'd5':this.display1List = (await this.$get(`/admin/getOthers5CourseDtoPage`, param1)).content;break;
        case 'd6':this.display1List = (await this.$get(`/admin/getOthers6CourseDtoPage`, param1)).content;break;
        case 'd7':this.display1List = (await this.$get(`/admin/getOthers7CourseDtoPage`, param1)).content;break;
        case 'd8':this.display1List = (await this.$get(`/admin/getOthers8CourseDtoPage`, param1)).content;break;
      }
      let param2 = {pageSize: 4,pageNum: 1,shoolId: this.indexData.schoolId};
      this.display2Lalbel = this.indexData.displayLabel2;
      switch (this.indexData.display2) {
        case 'da':this.display2List = (await this.$get(`/admin/getUndergraduateCourseDtoPage`, param2)).content;break;
        case 'db':this.display2List = (await this.$get(`/admin/getDeclarationCourseDtoPage`, param2)).content;break;
        case 'd1':this.display2List = (await this.$get(`/admin/getOthers1CourseDtoPage`, param2)).content;break;
        case 'd2':this.display2List = (await this.$get(`/admin/getOthers2CourseDtoPage`, param2)).content;break;
        case 'd3':this.display2List = (await this.$get(`/admin/getOthers3CourseDtoPage`, param2)).content;break;
        case 'd4':this.display2List = (await this.$get(`/admin/getOthers4CourseDtoPage`, param2)).content;break;
        case 'd5':this.display2List = (await this.$get(`/admin/getOthers5CourseDtoPage`, param2)).content;break;
        case 'd6':this.display2List = (await this.$get(`/admin/getOthers6CourseDtoPage`, param2)).content;break;
        case 'd7':this.display2List = (await this.$get(`/admin/getOthers7CourseDtoPage`, param2)).content;break;
        case 'd8':this.display2List = (await this.$get(`/admin/getOthers8CourseDtoPage`, param2)).content;break;
      }
    },
    getDisplay(idx) {
      let ret = {};
      this.showModules.forEach(ele => {
        this.$store.state.tabs.forEach(element => {
          if (element.menuId === ele.menuId && element.menuId === idx) {
            let e = element;
            e.name = ele.menuName;
            if (!e.path.startsWith("/" + this.$route.params.context)) {
              e.path = "/" + this.$route.params.context + e.path;
            }
            ret = e;
          }
        });
      });
      return ret;
    },
    toPage(path) {
      this.$router.push(`/${this.$route.params.context}/` + path);
    },
    async getSchoolEntity() {
      return await this.$get(`/admin/getSchoolByContext/${this.$route.params.context}`);
    },
    studiesDesc(studies) {
      var regPos = /^\d+(\.\d+)?$/; //非负浮点数
      if (!regPos.test(studies)) {
        return 0;
      }
      if (studies < 10000) {
        return studies;
      }
      return (parseFloat(studies) / 10000).toFixed(1) + "万";
    },
    async getIndexData() {
      const res = await this.$get("/school-index/findList/foreign", {
        foreign: "schoolId",
        value: (await this.getSchoolEntity()).id,
      });
      this.indexData = res[0] || {};
      this.initDisplay();
    },
    toWeb(){
      window.open(this.indexData.website)
    },
    showContent(idx) {
      let ret = false;
      this.showModules.forEach(element => {
        if (element.menuId === idx) {
          ret = true;
        }
      });
      return ret;
    },
    async initModule() {
      let id = (await this.getSchoolEntity()).id;
      let school = await this.$get("/school/findOne/" + id);
      let url = "https://aidedteachingdata.zhihuishu.com/aidedteachingData/firstUndergraduate/getPagePermission?schoolId=" + school.zhihuishuId;
      let res = await axios.get(url);
      this.showModules = res.data.rt.sort((a,b)=>a.sort-b.sort);

    },
    toDetail(obj) {
      this.$router.push(`/${this.$route.params.context}/news-detail/` + obj);
    },
    toCourseDetail(id, catalog) {
      this.$router.push(`/${this.$route.params.context}/course-view/${id}/${catalog}`);
    },
    async getNewsList() {
      const obj = await this.$get("/news/findPage",{
        pageSize: 4,
        pageNum: 1,
        sortType:'desc',
      });
      this.newsList = obj.content;
      this.newsTotal = obj.totalElements;
    },
    async getPolicyList() {
      const obj = await this.$get("/policy/findPage",{
        pageSize: 3,
        pageNum: 1,
      });
      this.policyList = obj.content;
      this.policyTotal = obj.totalElements;
    },
    async getUndergraduateList() {
      const obj = await this.$get("/admin/getUndergraduateCourseDtoPage",{
        pageSize: 8,
        pageNum: 1,
      });
      this.undergraduateList = obj.content;
      this.undergraduateTotal = obj.totalElements;
    },
    async getDeclarationList() {
      const obj = await this.$get("/admin/getDeclarationCourseDtoPage",{
        pageSize: 4,
        pageNum: 1,
      });
      this.declarationList = obj.content;
      this.declarationTotal = obj.totalElements;
    },
    async getOthers1List() {
      const obj = await this.$get("/admin/getOthers1CourseDtoPage",{
        pageSize: 4,
        pageNum: 1,
      });
      this.others1List = obj.content;
      this.others1Total = obj.totalElements;
    },
    async getOthers2List() {
      const obj = await this.$get("/admin/getOthers2CourseDtoPage",{
        pageSize: 4,
        pageNum: 1,
      });
      this.others2List = obj.content;
      this.others2Total = obj.totalElements;
    },
    //格式化时间
    handleTime(time, format, type) {
      if (type === 'getSingleDay') {
        return Time.getSingleDay(time);
      }
      return Time.getDay(time, format);
    },
  }
};
</script>
<style lang="scss" scoped>
.image-slot{display: flex;justify-content: center;align-items: center;height: 150px;}
.course-wrapper{width: 1200px;margin: 0 auto;border: solid 1px #eee; padding: 0 25px}
.apply-course-wrapper {width: 1200px;margin: 30px auto;}
.mol-thr{font-size: 24px; text-align: center; color: #fff;}
.mol-thr-f{font-size: 12px;  text-align: center;  color: #fff;  margin: 14px 0 0 0;}
.course-wrapper .main-title {font-size: 24px;line-height: 33px;margin: 0;padding:20px 0 0;font-weight: normal;}
.course-wrapper .image {width: 100%;height: 150px;display: block;}
.tit{font-size: 16px; color: #2a2a2a}
.introduce-wrapper{
  background-color: #eee;
  height: 395px;
  margin-bottom: -18px;
  padding-top: 56px;
  background-size: cover;
  .introduce{
    width: 1200px;
    margin: 0 auto;
      .el-button--primary:hover{
        background-color: #153495;
        border-color: #153495;
      }
    .title{
      // background: linear-gradient(173deg, #4885FF 0%, #112B86 100%);
      font-size: 34px;
      color: #4885FF;
    }
    .text{
      margin: 33px 0 30px;
      width: 554px;
      width: 554px;
      height: 88px;
      font-size: 14px;
      font-weight: 400;
      color: #676767;
      line-height: 22px;
    }
  }
}
.desc{
  font-weight: 400;  color: #999;  font-size: 14px;  line-height: 20px;
  width:260px;
  font-size: 12px; font-weight: 400; color: #999;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp:1;  /*要显示的行数*/
  -webkit-box-orient: vertical;
}
.study{color: #999; font-size: 12px; margin-top: 40px}
.study .point{color: #5676dc}

.read-more{margin: 20px 0; text-align: center;}
.apply-course-title {
  background-image: url(~../../assets/images/home/course-bg.png);
  background-size: 60px;
  background-repeat: no-repeat;
  font-size: 22px;
  line-height: 28px;
  margin-left: 6px;
}
.all-font{text-align:center;font-size:12px;margin-top:18px;color:#999}
.apply-wrapper {
  background-image: url(~../../assets/images/home/apply_bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 142px
}
.apply-wrapper:not(.last) {margin-right: 8px;}
/* 课程展示 */
.course-show-wrapper {
  background-image: url(~../../assets/images/home/kecheng-3.png);
  background-repeat: no-repeat;
  background-size:cover;
  padding: 40px 0 30px 0;
  margin-top: 40px;
}

.course-show-wrapper .course-info-wrapper{
  border-radius: 4px;
  border: solid 1px transparent;
}
.course-show-wrapper .course-info-wrapper:hover{
  border-color: #5676dc;
}

.course-show-wrapper .course-show {width: 1200px;margin: 0 auto;}
.course-show-wrapper .course-show .course-info{padding: 14px; background: #fff}
.course-show-wrapper .course-show .course-info .info-tit{font-size: 16px; font-weight: 600; color: #2a2a2a;}
.course-show-wrapper .course-show .course-info .info-desc{font-weight: 400;  color: #adadad;  font-size: 12px;  line-height: 15px;  margin: 10px 0 20px 0;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp:2;  /*要显示的行数*/
  -webkit-box-orient: vertical;
  height: 30px;
}
.course-show-wrapper .course-show .course-info .info-img{color: #999;  font-size: 12px;  display: flex;  align-items: center;}
.course-show-wrapper .course-show .course-info .teacher{font-size: 12px; font-weight: 400; line-height: 20px; margin-left: 12px;color: #2a2a2a;}
.course-more{text-align:center;padding:20px 0 0;}

.news-wrapper{clear:both;padding:32px 0;width:1200px;margin:0 auto;height:auto;overflow:hidden;display:flex;justify-content: space-between;align-items: flex-start;}
.news-title{border-bottom: solid 1px #e1e6ec; height: 40px;}
.news-title b{font-size:24px;font-weight:400;}
.more-list{color: #999; float: right}
.news-items{display: flex; margin-top: 20px;justify-content:flex-start;cursor:pointer;}
.news-items:hover .news-tit{color: #5676dc;}
.news-info{margin-left: 16px;display: flex;flex-direction: column;justify-content: space-between;}
.news-tit{font-size: 16px; font-weight: 600; color: #2a2a2a;margin-bottom: 8px;}
.news-img{width: 177px;display:block;}
.news-img img{width:100%;height:100px;}
.news-desc{
  width:630px;
  font-size: 12px; font-weight: 400; color: #999;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp:1;  /*要显示的行数*/
  -webkit-box-orient: vertical;
}
.news-time{font-size: 12px;  font-weight: 400;  color: #666;}

.policy-wrap{border-bottom: solid 1px #e1e6ec; height: 40px}
.policy-title{font-size: 24px}
.policy-item {
  background-image: url(~../../assets/images/home/policy_bg.png);
  background-repeat: no-repeat;
  background-size:cover;
  height: auto;
  margin-top: 20px;
  padding:30px;
}
.policy-item .time{color: #999; font-size: 14px; font-weight: 600}
.policy-tit{color: #333; font-size: 15px; font-weight: 600}
.policy-inf{display: flex;  justify-content: space-between;  align-items: center;  margin-top: 8px;}
.policy-inf .el-button--primary{
  border-color: #5676dc;
  background-color: #5676dc;
}


.course-card{
  border-color: #eee;
  &:hover{
    border-color: #5676dc;
  }
}
.download-href {
  width: 78px;
  border-radius: 3px;
  border: none;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 15px;
}
</style>
<style>
.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}
</style>