<template>
  <div class="course-wrapper">
    <div class="course-title">实验教学项目描述</div>
    <div class="content-wrapper">
      <div class="content">
          <div class="cont-title"><span class="circle"></span>实验目的</div>
          <div class="cont-intro">
          </div>
          <div class="cont-title"><span class="circle"></span>实验仪器设备</div>
          <div class="cont-intro"></div>
      </div>
      <div class="course-menu">
        <ul class="menu">
          <li class="menu-item active">实验目的</li>
          <li class="menu-item">实验仪器设备</li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  methods: {
  }
}
</script>
<style scoped>
.course-wrapper{
  width:1200px;
  min-height:300px;
  margin:0 auto 40px;
  padding:0 40px;
  background: #FFFFFF;
  border-radius: 12px;
}
.course-title{
  margin:0 -40px;
  padding:0 40px;
  height:90px;
  font-size: 24px;
  font-weight: 600;
  color: #2A2A2A;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom:#eee solid 1px;
}
.course-title span{
  font-size: 12px;
  line-height: 18px;
  color: #777;
  margin-left: 8px;
  font-weight:normal;
  padding:10px 0 0;
}

.content-wrapper{clear:both;display:flex;align-items: flex-start;justify-content: space-between;padding:30px 0 0;}
.content{width:900px;}
.cont-title{
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2A2A2A;
  line-height: 28px;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  padding:0 0 20px;
}
.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 12px;
  background-color: #3d84ff;
  display: inline-block;
}
.cont-intro{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777777;
  line-height: 28px;
  padding:0 0 40px 20px;
}
/*右侧滚动锚点*/
.course-menu {width: 150px;}
.menu {list-style: none;border-left: solid 1px #eee;padding-left: 0;}
.menu-item{
  margin-bottom: 15px;
  color: #777;
  padding-left: 22px;
  font-size: 14px;
  line-height: 24px;
  border-left: solid 3px transparent;
  display:block;
  text-overflow: ellipsis;
  white-space:nowrap;
  overflow:hidden;
}
.menu-item.active {border-left-color: #3d84ff;color: #2a2a2a;}
</style>