<template>
  <LayoutHome>
    <!--Banner-->
    <div class="banner note" :style="{backgroundImage: 'url(' + setPolicyBackground + ')'}">
      <h3 class="main-title">{{setPolicyName}}</h3>
      <h4 class="sub-title">{{setPolicyDescription}}</h4>
    </div>
    <!--List-->
    <div class="policy-wrapper">
      <el-row :gutter="8">
        <div class="policy-list">
          <div class="list-show" v-for="(policy, i) in policyList" :key="i" :span="6">
            <div class="list-show-info">
              <div class="info-show">
                <el-tooltip effect="dark" :content="policy.title" placement="top-start">
                <b>{{ policy.title }}</b>
                </el-tooltip>
                <em>{{ policy.publishTime }}</em>
              </div>
              <a class="download-href" :style="{backgroundColor:themeColor}" :href="policy.file1AttachmentUrl" :download="policy.file1AttachmentName" icon="el-icon-download">点击下载</a>
            </div>
          </div>
        </div>
      </el-row>
      <div class="page">
        <span @click="handlePageChange(currentPage - 1)" class="prev" :class="{ disable: 1 === currentPage }">上一页</span>
        <span @click="handlePageChange(i)" class="num" :style="{color:i === currentPage ? '#fff' : '#666',backgroundColor:i=== currentPage ? themeColor : '#fff'}" v-for="i in totalPages" :key="i">{{ i }}</span>
        <span @click="handlePageChange(currentPage + 1)" class="next" :class="{ disable: currentPage === totalPages }">下一页</span>
      </div>
    </div>
  </LayoutHome>
</template>
<script>
import LayoutHome from "./../../components/Layout";
import Time from "./../../plugins/date";
import { getCookie,setCookie,removeCookie,getLoginUUId,getLoginUserId,getLoginRealName,getLoginUserName,getLoginUserUrl } from "../../plugins/cookie";
export default {
  components: {
    LayoutHome,
  },
  data() {
    return {
      formInline: {
        pageSize: 8,
        pageNum: 1,
      },
      policyList: [],
      totalElements: 0,
      totalPages:0,
      currentPage:1,
      setPolicyName:'',
      setPolicyDescription:'',
      setPolicyBackground:'',
    };
  },
  computed:{
    themeColor(){
        return this.$store.state.themeColor;
      },
  },
  created() {
    this.getPolicyList();
    this.getPolicySet();
  },
  methods: {
    async getSchoolEntity() {
      let school = await this.$get(`/admin/getSchoolByContext/${this.$route.params.context}`);
      return school;
    },
    async getPolicySet(){
      const policyDate = await this.$get("/school-policy/findList/foreign", {foreign:"schoolId", value:(await this.getSchoolEntity()).id});
      this.setPolicyName = policyDate[0].name;
      this.setPolicyDescription = policyDate[0].description;
      this.setPolicyBackground = policyDate[0].backgroundAttachmentUrl;
    },
    toDownload(url) {
      this.$downFile(url);
    },
    handlePageChange(pageNum) {
      if (pageNum < 1 || pageNum > this.totalPages) return;
      this.getPolicyList({ pageNum });
    },
    async getPolicyList(data={}) {
      const params = {...this.formInline,...data};
      const policyData = await this.$get("/policy/findPage", params);
      this.policyList = policyData.content;
      this.newsTotal = policyData.totalElements;
      this.totalPages = policyData.totalPages;
      this.currentPage = params.pageNum;
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  background-repeat: no-repeat;
  background-position: center bottom;
  height:270px;
  padding:94px 0 0;
}
.policy-wrapper {
  width: 1200px;
  margin: 0 auto;
  border: solid 1px #eee;
  padding: 10px 20px;
  position: relative;
  top: -52px;
  background: #fff;
  border-radius: 8px;
}

.main-title {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  line-height: 46px;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 5px 0;
}
.sub-title {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  line-height: 29px;
  text-align: center;
  margin: 0;
}
.policy-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.list-show {
  width: 50%;
  padding: 10px;
}
.list-show-info {
  &:hover{
    border: solid 1px #5676dc;
      }
  height: 144px;
  border: solid 1px transparent;
  border-radius: 5px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url(~../../assets/images/policy/listbg.png) no-repeat right center;
}
.info-show {
  padding-right: 26px;
}
.info-show b {
  color: #333333;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  line-height: 29px;
}
.info-show em {
  font-style: normal;
  font-weight: 400;
  color: #999999;
  line-height: 29px;
  display: block;
  padding: 15px 0 0;
}
.download-href {
  width: 148px;
  height: 50px;
  // background: #5676dc;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
<style>
.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}
</style>