<template>
  <div class="course-wrapper">
    <div class="course-title">教学团队</div>
    <div class="content-wrapper">
      <div class="content">
        <div class="cont-list">
          <div class="img-detail">
            <img :src="courseData && courseData.length && courseData[0].portrait" />
          </div>
          <div class="info-detail">
            <div v-if="courseData && courseData.length">
              <div class="detail-name">
                {{ courseData[0].name
                }}<span>{{ courseData[0].typeDescription }}</span
                ><font
                  >{{ courseData[0].titleDescription }}·
                  {{ courseData[0].companyTypeDescription }}</font
                >
              </div>
              <div class="detail-desc">
                <!-- 性别：女  -->
                <!-- 出生年月：1988.12.12  -->
                <!-- 学历：本科  -->
                <!-- 学位：硕士 -->
              </div>
              <div class="detail-items">
                <div class="items-tit">教学研究情况</div>
                <div class="items-intro" v-html="courseData[0].teaching || '暂无数据'"></div>
                <div class="items-tit">学术研究情况</div>
                <div class="items-intro" v-html="courseData[0].scholarism || '暂无数据'"></div>
              </div>
              <div v-if="courseData && courseData.length>0">
                <div class="items-tit">团队其他主要成员</div>
                <div class="items-tit-desc">
                  <span>项目团队总人数：<font>{{courseData.length}}</font>人</span>
                  <span>高校人员数量：<font>1</font>人</span>
                  <span>企业人员数量：<font>{{courseData.length -1}}</font>人</span>
                </div>
                <div class="items-list">
                  <el-table :data="otherCourseData" style="width: 100%">
                    <el-table-column prop="name" label="姓名" width="180"></el-table-column>
                    <el-table-column prop="companyTypeDescription" label="所在单位" width="150"></el-table-column>
                    <el-table-column prop="position" label="专业技术职务" width="120"></el-table-column>
                    <el-table-column prop="titleDescription" label="行政职务" width="80"></el-table-column>
                    <el-table-column prop="task" label="承担任务">
                      <template slot-scope="scope">
                        <div v-html="scope.row.task"></div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="course-menu">
        <ul class="menu">
          <li v-for="(item,i) in courseData" :key="i" class="menu-item" :class="{active:i === 0}">{{item.name}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.initData();
  },
  data() {
    return {
      courseData: [],
      courseId: this.$route.params.id,
      tableData: [
        {
          name: "张老师",
          org: "武汉城市职业学院",
          service: "专业技术职务",
          duties: "行政职务",
          task:
            "承担任务承担任务承担任务承担任务承担任务承担任务承担任务承担任务承担",
        },
        {
          name: "张老师",
          org: "武汉城市职业学院",
          service: "专业技术职务",
          duties: "行政职务",
          task:
            "承担任务承担任务承担任务承担任务承担任务承担任务承担任务承担任务承担",
        },
      ],
    };
  },
  computed: {
    catalog() {
      return this.$route.params.catalog;
    },
    otherCourseData(){
      return this.courseData.filter((item,i)=>i>0)
    }
  },
  methods: {
    initData() {
      this.getTeamData();
    },
    async getTeamData() {
      let courseData = await this.$get(`/web/findTeamByCourseIdType`, {
        id: this.courseId,
        type: this.catalog,
      });
      this.courseData = courseData;
      this.courseId = courseData.id;
    },
  },
};
</script>
<style scoped>
.course-wrapper {
  width: 1200px;
  min-height: 300px;
  margin: 0 auto 40px;
  padding: 0 40px;
  background: #ffffff;
  border-radius: 12px;
}
.course-title {
  margin: 0 -40px;
  padding: 0 40px;
  height: 90px;
  font-size: 24px;
  font-weight: 600;
  color: #2a2a2a;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: #eee solid 1px;
}
.course-title span {
  font-size: 12px;
  line-height: 18px;
  color: #777;
  margin-left: 8px;
  font-weight: normal;
  padding: 10px 0 0;
}
.content-wrapper {
  clear: both;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0;
}
.content {
  width: 900px;
}
.cont-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.cont-list .img-detail {
  width: 84px;
  height: 84px;
  border-radius: 42px;
  margin-right: 20px;
}
.cont-list .img-detail img {
  width: 84px;
  height: 84px;
  border-radius: 42px;
}
.cont-list .info-detail {
  width: 100%;
  padding: 8px 0 0;
}
.cont-list .info-detail .detail-name {
  font-size: 20px;
  font-weight: 600;
  color: #2a2a2a;
  line-height: 28px;
}
.cont-list .info-detail .detail-name span {
  font-size: 12px;
  font-weight: 400;
  color: #3d84ff;
  line-height: 35px;
  padding-left: 15px;
}
.cont-list .info-detail .detail-name font {
  font-size: 12px;
  line-height: 18px;
  color: #999;
  margin-left: 15px;
  font-weight: normal;
  padding: 10px 0 0;
}
.cont-list .info-detail .detail-desc {
  font-size: 12px;
  font-weight: 400;
  color: #999;
  line-height: 28px;
}
.cont-list .info-detail .detail-desc span {
  color: #777;
  padding-left: 35px;
}
.cont-list .info-detail .detail-items {
  padding: 30px 0;
}
.cont-list .info-detail .detail-items .items-tit {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.cont-list .info-detail .detail-items .items-tit:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 12px;
  background-color: #9aa3b9;
  display: inline-block;
  vertical-align: middle;
}
.items-tit-desc {
  font-size: 12px;
  color: #777;
  /* padding: 10px 0 0 20px; */
  margin: 6px 0 8px;
}
.cont-list .info-detail .detail-items .items-tit-desc font {
  color: #3d84ff;
  font-size: 14px;
  padding: 0 5px;
}
.cont-list .info-detail .detail-items .items-intro {
  color: #777;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 0 20px 20px;
}
.cont-list .info-detail .detail-items .items-list {
  padding: 15px 0 13px 20px;
}

/*右侧滚动锚点*/
.course-menu {
  width: 150px;
}
.menu {
  list-style: none;
  border-left: solid 1px #eee;
  padding-left: 0;
}
.menu-item {
  margin-bottom: 15px;
  color: #777;
  padding-left: 22px;
  font-size: 14px;
  line-height: 24px;
  border-left: solid 3px transparent;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.menu-item.active {
  border-left-color: #3d84ff;
  color: #2a2a2a;
}
</style>