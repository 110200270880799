<template>
  <el-tabs class="course-tab" v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="基本信息" name="tab1">
      <courseBasic />
    </el-tab-pane>
    <!--课程基本信息-->
    <!--
    <el-tab-pane v-if="catalog === 'experiment'" label="实验教学项目描述" name="tab2">
      <courseDesc />
    </el-tab-pane>
    -->
    <!--实验教学项目描述 仅#虚拟仿真实验-->
    <!--
    <el-tab-pane v-else label="课程特色" name="tab3">
      <courseFeatures />
    </el-tab-pane>
    -->
    <!--课程特色 除#虚拟仿真实验-->
    <el-tab-pane v-if="catalog !== 'experiment'" label="教学团队" name="tab4">
      <courseTeam />
    </el-tab-pane>
    <!--教学团队-->
    <el-tab-pane v-else label="虚拟仿真实验教学团队" name="tab5">
      <courseTeam_virtual />
    </el-tab-pane>
    <!--教学团队 仅#虚拟仿真实验-->
    <el-tab-pane label="课程建设应用" name="tab6">
      <courseApp /> </el-tab-pane
    ><!--课程建设应用-->
  </el-tabs>
</template>
<script>
import courseBasic from "./../../pages/course/courseView/courseBasic";
import courseDesc from "./../../pages/course/courseView/courseDesc";
import courseFeatures from "./../../pages/course/courseView/courseFeatures";
import courseTeam from "./../../pages/course/courseView/courseTeam";
import courseTeam_virtual from "./../../pages/course/courseView/courseTeam_virtual";
import courseApp from "./../../pages/course/courseView/courseApp";
export default {
  components: {
    courseBasic,
    courseDesc,
    courseFeatures,
    courseTeam,
    courseTeam_virtual,
    courseApp,
  },
  created() {
    // console.log(this.catalog)
  },
  computed: {
    userInfo: function () {
      return this.$store.state.userInfo;
    },
    catalog() {
      return this.$route.params.catalog;
    },
    themeColor(){
      console.log('this.$store.state.themeColor;',this.$store.state.themeColor)
        return this.$store.state.themeColor;
      },
  },
  data() {
    return {
      activeName: "tab1",
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    },
  },
  watch:{
    themeColor(){
      try {
        document.body.querySelector('.el-tabs__active-bar').style.backgroundColor = this.themeColor;
        console.log(document.body.querySelector('.el-tabs__active-bar'))
      } catch (error) {
        console.error(error)
      }
    }
  }
};
</script>
<style lang="scss">
.course-tab .el-tabs__item {
  line-height: 24px;
  height: 32px;
}
.course-tab .el-tabs__nav-scroll{
  height: 87px;
}
</style>