<template>
  <div class="course-wrapper">
    <div class="course-title">教学团队</div>
    <div class="content-wrapper">
      <div class="content">
        <div class="cont-list">
          <div class="img-detail" v-if="courseData[0]">
            <img :src="courseData[0] ? courseData[0].portrait :''" />
          </div>
          <div class="info-detail">
            <div v-if="courseData && courseData.length">
              <div class="detail-name">
                {{ courseData[0].name
                }}<span>{{ courseData[0].typeDescription }}</span>
              </div>
              <div class="detail-desc">
                {{ courseData[0].title }} {{ courseData[0].position }} ·
                {{ courseData[0].vassalage }}
                <!-- <span>
                  手机号：{{ courseData[0].mobile }} 邮箱：{{
                    courseData[0].email
                  }}
                </span> -->
              </div>
              <div class="detail-items">
                <div class="items-tit">教学经历</div>
                <div
                  class="items-intro"
                  v-html="courseData[0].experience"
                ></div>
                <div class="items-tit">教学任务</div>
                <div class="items-intro" v-html="courseData[0].task"></div>
              </div>
              <div v-if="otherTeam && otherTeam.length > 0">
                <div class="items-tit" style="margin-bottom:10px">团队其他主要成员</div>
                <div class="items-list">
                  <div class="list-view" v-for="item in otherTeam" :key="item.id">
                    <div class="view-img">
                      <img :src="item.portrait" />
                    </div>
                    <div class="view-inf">
                      <b>
                        <em>{{item.name}}</em>
                        <span
                          >{{item.position}} | {{item.title}}|
                          {{item.vassalage}}</span
                        >
                      </b>
                      <div class="info-show">
                        <em>教学任务：</em>
                        <dd v-html="item.task  || '暂无数据'">
                        </dd>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="course-menu">
        <ul class="menu">
          <li v-for="(item,i) in courseData" :key="item.id" class="menu-item" :class="{active:i===0}">{{item.name}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.initData();
  },
  data() {
    return {
      courseData: {},
      courseId: this.$route.params.id,
    };
  },
  computed: {
    catalog() {
      return this.$route.params.catalog;
    },
    id() {
      return this.$route.params.id;
    },
    otherTeam() {
      return this.courseData && this.courseData.length > 0
        ? this.courseData.filter(((item, i) => i > 0))
        : [];
    },
  },
  methods: {
    initData() {
      this.getCourseData();
    },
    async getCourseData() {
      const { content } = await this.$get(
        `/course-${this.catalog}-team/findPage`,
        {
          [`course${this.toUpperCaseFirst(this.catalog)}Id`]: this.id,
        }
      );
      // console.log(content);
      this.courseData = content;
    },
    toUpperCaseFirst(str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
    },
  },
};
</script>
<style scoped>
.course-wrapper {
  width: 1200px;
  min-height: 300px;
  margin: 0 auto 40px;
  padding: 0 40px;
  background: #ffffff;
  border-radius: 12px;
}
.course-title {
  margin: 0 -40px;
  padding: 0 40px;
  height: 90px;
  font-size: 24px;
  font-weight: 600;
  color: #2a2a2a;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: #eee solid 1px;
}
.course-title span {
  font-size: 12px;
  line-height: 18px;
  color: #777;
  margin-left: 8px;
  font-weight: normal;
  padding: 10px 0 0;
}
.content-wrapper {
  clear: both;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0;
}
.content {
  width: 900px;
}
.cont-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.cont-list .img-detail {
  width: 84px;
  height: 84px;
  border-radius: 42px;
  margin-right: 20px;
}
.cont-list .img-detail img {
  width: 84px;
  height: 84px;
  border-radius: 42px;
}
.cont-list .info-detail {
  width: 100%;
  padding: 8px 0 0;
}
.cont-list .info-detail .detail-name {
  font-size: 20px;
  font-weight: 600;
  color: #2a2a2a;
  line-height: 28px;
}
.cont-list .info-detail .detail-name span {
  font-size: 14px;
  font-weight: 400;
  color: #3d84ff;
  line-height: 35px;
  padding-left: 15px;
}
.cont-list .info-detail .detail-desc {
  font-size: 14px;
  font-weight: 400;
  color: #2a2a2a;
  line-height: 28px;
}
.cont-list .info-detail .detail-desc span {
  color: #777;
  padding-left: 35px;
}
.cont-list .info-detail .detail-items {
  padding: 30px 0;
}
.cont-list .info-detail .detail-items .items-tit {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.cont-list .info-detail .detail-items .items-tit:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 12px;
  background-color: #9aa3b9;
  display: inline-block;
  vertical-align: middle;
}
.cont-list .info-detail .detail-items .items-intro {
  color: #777;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 0 20px 20px;
}
.cont-list .info-detail .detail-items .items-list {
  padding: 15px 0 13px 20px;
}

.list-view {
  border: #eee solid 1px;
  margin-bottom: 10px;
  padding: 20px;
  display: flex;
}
.list-view .view-img {
  width: 40px;
  height: 40px;
  border-radius: 42px;
}
.list-view .view-img img {
  width: 40px;
  height: 40px;
  border-radius: 42px;
}
.list-view .view-inf {
  padding-left: 15px;
}
.list-view .view-inf b {
  font-weight: 400;
  font-size: 16px;
  padding: 8px 0 0;
  display: block;
}
.list-view .view-inf b em {
  width: 80px;
  display: inline-block;
  font-style: normal;
  color: #2a2a2a;
}
.list-view .view-inf b span {
  font-size: 12px;
  color: #777;
}
.list-view .view-inf .info-show {
  padding: 10px 0 0;
}
.list-view .view-inf .info-show em {
  font-style: normal;
  font-size: 12px;
  color: #777;
  display: block;
  float: left;
  padding: 7px 0 0;
}
.list-view .view-inf .info-show dd {
  font-size: 12px;
  color: #777;
  line-height: 20px;
  margin: 0 0 0 80px;
  display: block;
}
/*右侧滚动锚点*/
.course-menu {
  width: 150px;
}
.menu {
  list-style: none;
  border-left: solid 1px #eee;
  padding-left: 0;
}
.menu-item {
  margin-bottom: 15px;
  color: #777;
  padding-left: 22px;
  font-size: 14px;
  line-height: 24px;
  border-left: solid 3px transparent;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.menu-item.active {
  border-left-color: #3d84ff;
  color: #2a2a2a;
}
</style>