<template>
  <div class="course-wrapper">
    <div class="course-title"><span class="circle" :style="{backgroundColor:themeColor}"></span> 课程基本信息</div>
    <div class="course-form">
      <div class="form-wrap">
        <div class="form-view">
          <el-row>
            <el-col v-for="(item, i) in detailList" :key="i" :span="8" class="view-list">
              <label>{{ item.title }}：</label>
              <el-tooltip v-if="item.content" effect="dark" :content="item.content + (item.subContent ? item.subContent : '')" placement="top-start">
                <b>{{ item.content }}
                  <font v-if="item.subContent" v-html="item.subHtml"></font>
                </b>
              </el-tooltip>
              <span style="color:#777" v-else>——</span>
            </el-col>
          </el-row>
        </div>
        <div class="form-img" id="container">
          <img :src="courseData.coverAttachmentUrl || `/images/cover/${catalog}.png`" class="image" />
        </div>
      </div>
      <div class="form-intro">
        <label>课程介绍：</label>
        <dd v-html="courseData.introduce"></dd>
      </div>
    </div>
    <div class="title mien" style="margin-top: 30px">
      <div class="circle" :style="{backgroundColor:themeColor}"></div>
      <span class="text">课堂风采</span>
      <div class="mien-info">
        <div class="mien-item" v-for="{ id, fileName, fileType,filePreview ,lastModifiedDate} in fileList" :key="id" @click="previewFile(filePreview, fileName,fileType)">
          <i class="type-img icon iconfont" :class="getIcon(fileType)" />
          <span class="title">{{ fileName }}</span>
          <span style="color:#2a2a2a;font-size:14px">{{lastModifiedDate}}</span>
        </div>
      </div>
    </div>
    <el-dialog :title="previewTitle" :visible="previewVislble" :before-close="closePreview">
      <div v-if="['video','audio'].includes(priviewType)" style="height:500px" id="preview-video"></div>
      <iframe v-else :src="previewUrl" class="preview-wrapper"></iframe>
      <div slot="footer">
        <el-button @click="closePreview" type="primary">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  created() {
    this.initData();
  },
  data() {
    return {
      courseData: {},
      fileList: [],
      courseId: this.$route.params.id,
      previewVislble: false,
      previewTitle: "",
      previewUrl: "",
      priviewType:''
    };
  },
  computed: {
    themeColor(){
        return this.$store.state.themeColor;
      },
    catalog() {
      return this.$route.params.catalog;
    },
    detailList() {
      return [
        {
          title: "课程负责人",
          key: "manager",
          content: this.courseData.manager,
        },
        // {
        //   title: "所属学校",
        //   key: "vassalage",
        //   content: this.courseData.vassalage,
        // },
        // { title: "职称", key: "title", content: this.courseData.title },
        {
          title: "课程模式",
          key: "type",
          content: this.courseData.typeDescription,
        },
        { title: "学分", key: "credit", content: this.courseData.credit },
        {
          title: "学时",
          key: "hour",
          content: parseFloat(
            (this.courseData.hour || 0) +
              (this.courseData.hourOnline || 0) +
              (this.courseData.hourOffline || 0) +
              (this.courseData.hourTheory || 0) +
              (this.courseData.hourPractice || 0)
          ).toFixed(1),
          subContent: this.hourTemplate,
          subHtml: this.hourTemplateHtml,
        },
        {
          title: "课程类型",
          key: "kind",
          content: this.courseData.kindDescription,
          hidden: this.courseData.type === "experiment",
        },
        {
          title: "实验人数",
          key: "experimentPeople",
          content: this.courseData.experimentPeople,
          hidden: this.courseData.type !== "experiment",
        },
        {
          title: "课程性质",
          key: "property",
          content: this.courseData.propertyDescription,
          hidden: this.courseData.type === "experiment",
        },
        {
          title: "课程对象",
          key: "student",
          content: this.courseData.student,
          hidden: this.courseData.type !== "online",
        },
        {
          title: this.platformLabel,
          key: "platform",
          content: this.courseData.platform,
          hidden: ["online", "offline"].includes(this.catalog),
        },
        {
          title:this.urlLabel,
          key: "url",
          content: this.courseData.url,
          hidden: !["experiment", "online","practice"].includes(this.catalog),
        },
        {
          title:
            this.courseData.type === "experiment" ? "对应专业" : "面向专业",
          key: "major",
          content: this.courseData.majorDescription,
          hidden: this.courseData.type === "online",
        },
      ].filter((item) => !item.hidden);
    },
    urlLabel() {
      switch (this.catalog) {
        case "experiment":
          return "链接地址";
        case "practice":
          return "基地链接";
        default:
          return "课程链接";
      }
    },
    platformLabel() {
       switch (this.catalog) {
        case "practice":
          return "实践基地名称";
        default:
          return "主要开课平台";
      }
    },
    hourTemplate() {
      switch (this.catalog) {
        // 社会实践
        case "practice":
          return `（理论学时${
            this.courseData.hourOnline || 0
          }   实践学时 ${this.courseData.hourOffline || 0}）`;
          break;
        // 混合课程
        case "mixture":
          return `（线上学时${
            this.courseData.hourOnline || 0
          }   学堂学时 ${this.courseData.hourOffline || 0}）`;
          break;
        // 实验课程
        case "experiment":
          return `（实验所属课程所占课时${
            this.courseData.hourOnline || 0
          }   该实验项目所占课时 ${this.courseData.hourOffline || 0}）`;
        default:
          return "";
      }
    },
    hourTemplateHtml() {
      switch (this.catalog) {
        // 社会实践
        case "practice":
          return `<span class="subcontent">（理论学时${
            this.courseData.hourOnline || 0
          }   实践学时 ${this.courseData.hourOffline || 0}）</span>`;
          break;
        // 混合课程
        case "mixture":
          return `<span class="subcontent">（线上学时${
            this.courseData.hourOnline || 0
          }   学堂学时 ${this.courseData.hourOffline || 0}）</span>`;
          break;
        // 实验课程
        case "experiment":
          return `<span class="subcontent">（实验所属课程所占课时${
            this.courseData.hourOnline || 0
          }   该实验项目所占课时 ${this.courseData.hourOffline || 0}）</span>`;
        default:
          return "";
      }
    },
    hourTitle() {
      if (this.pageType !== "edit") return "学时";
      switch (this.courseData.type) {
        // 社会实践
        case "practice":
          return `学时（理论学时/实践学时）`;
          break;
        // 混合课程
        case "mixture":
          return `学时（线上学时/学堂学时）`;
        // 实验课程
        case "experiment":
          return `学时（实验所属课程所占课时/该实验项目所占课时`;
        default:
          return "学时";
      }
    },
    idName() {
      let catalogKey = {
        online: "courseOnlineId",
        offline: "courseOfflineId",
        mixture: "courseMixtureId",
        experiment: "courseExperimentId",
        practice: "coursePracticeId",
      };
      return catalogKey[this.catalog];
    },
  },
  methods: {
    initData() {
      this.getCourseData();
      this.getFileList();
    },
    async previewFile(filePreview, fileName,fileType) {
      if(['video','audio'].includes(fileType)){
        this.previewTitle = fileName;
        this.priviewType = fileType;
        this.previewVislble = true;
        setTimeout(() => {this.play(filePreview,'#preview-video');},0);
        return;
      }
      if(!filePreview){
        this.$message.error("该文件不支持预览！");
        return;
      }
      const res = await axios({
        url: `https://app-hike.zhihuishu.com/appAidedteaching/file/findKczyFilePreviewUrl?dataId=${filePreview}`,
        methods: "GET",
      });
      if (res && res.data && res.data.status == "200") {
        if (!res.data.rt.url) {
          this.$message.error("该文件不支持预览！");
          return;
        }
        this.previewVislble = true;
        this.previewTitle = fileName;
        this.previewUrl = res.data.rt.url;
        return;
      }
      this.$message.error("预览失败！");
    },
    closePreview() {
      this.previewVislble = false;
      this.previewTitle = "";
      this.previewUrl = "";
      this.priviewType = '';
    },
    getIcon(type) {
      // audio, compress, excel, image, other, pdf, powerpoint, text, video, word
      const iconList = {
        audio: "icon_voice",
        compress: "icon_zip",
        excel: "icon_ex",
        image: "icon_pic",
        other: "icon_other",
        pdf: "icon_pdf",
        powerpoint: "icon_ppt",
        text: "icon_txt",
        video: "icon_viedo",
        word: "icon_word",
        notopen: "icon_notopen",
        html: "icon_html",
      };
      return `icon-${iconList[type] || "icon_other"}`;
    },
    async getFileList() {
      const res = await this.$get(`/course-${this.catalog}-material/findPage`, {
        [this.idName]: this.courseId,
        pageSize: 10000,
      });
      this.fileList = res.content;
    },
    async getCourseData() {
      let courseData = await this.$get(`/web/findBaseByCourseIdType`, {
        id: this.courseId,
        type: this.catalog,
      });
      this.courseData = courseData;
      this.courseId = courseData.id;
    },
    play(id,DOMId) {
      $(DOMId || "#container").Ableplayer(
        {
          id: id, //视频ID 必填  id和src  二选一
          control: {
            //播放器按钮显示控制
            nextBtn: false, //下一节 默认false
            rateBtn: false, //速率 默认显示
            fullBtn: false, //全屏 默认显示
            volumeBtn: false, //音量 默认显示
            trackBtn: false, //字幕 默认显示
            definiBtn: false, //清晰度 默认显示

            bigPlayerBtn: false, //大播放按钮 默认显示
            playTime: false, //进度时间 默认显示
            errorBar: false, //错误提示 默认显示
          },
        },
        {
        }
      );
    },
  },
  watch: {
    courseData(n) {
      if (this.courseData.shortVideoAttachmentId) {
        this.play(this.courseData.shortVideoAttachmentId);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}
.course-wrapper {
  width: 1200px;
  min-height: 300px;
  margin: 0 auto 40px;
  padding: 0 40px 40px;
  background: #ffffff;
  border-radius: 12px;
}
.course-title {
  font-size: 20px;
  font-weight: 600;
  color: #2a2a2a;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
}
.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 12px;
  background-color: #3d84ff;
  display: inline-block;
}
.course-form {
  padding: 20px 30px;
  background: #f4f8ff;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.form-wrap {
  width: 100%;
  // display: flex;
  // align-items: flex-start;
  // justify-content: space-between;
}
.form-view {
  // flex-grow: 1;
  width: 710px;
  display: block;
  float: left;
}
.form-view .view-list {
  padding: 10px 0;
  min-height: 71px;
}
.form-view label {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  line-height: 20px;
  display: block;
}
.form-view b {
  font-size: 16px;
  font-weight: 600;
  color: #2a2a2a;
  line-height: 22px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px 50px 0 0;
}
.form-view em {
  font-size: 16px;
  font-weight: 600;
  color: #2a2a2a;
  line-height: 22px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 50px 0 0;
  font-style: normal;
}
.form-view em font {
  font-size: 12px;
  font-weight: 400;
  color: #777777;
  line-height: 17px;
}
.form-view a {
  font-weight: normal;
  font-size: 12px;
  color: #3d84ff;
  line-height: 17px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 50px 0 0;
  overflow: hidden;
}
#container{
  height: 200px;
  flex:0 0 350px;
  .play {
      width: 31px;
      height: 31px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-15.5px) translateY(-15.5px) rotateZ(-90deg);
      text-align: center;
    }
}
.form-img {
  width: 350px;
  float: left;
  display: block;
}
.form-img img {
  width: 100%;
  max-height: 150px;
}
.form-intro {
  width: 100%;
  display: block;
  padding: 20px 0 10px;
}
.form-intro label {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777777;
  line-height: 20px;
  display: block;
}
.form-intro dd {
  display: block;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2a2a2a;
  line-height: 24px;
  margin: 0;
  padding: 10px 0;
}

.mien-info {
  padding-top: 18px;
  border-radius: 12px;
  //   background-color: #f4f8ff;
  .mien-item {
    display: flex;
    align-items: center;
    padding: 14px 20px;
    cursor: pointer;
    &:nth-of-type(odd) {
      background: #f4f8ff;
    }
    .type-img {
      width: 40px;
      height: 40px;
      margin-right: 14px;
      font-size: 40px;
    }
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #2a2a2a;
      line-height: 20px;
      flex-grow: 1;
    }
  }
}
.preview-wrapper {
  border: 0;
  width: 100%;
  height: 500px;
  overflow-y: auto;
}
</style>