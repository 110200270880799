<template>
  <LayoutHome>
    <!--Banner-->
    <Banner />
    <!--Content-->
    <Tab />
  </LayoutHome>
</template>
<script>
import LayoutHome from "./../../../components/Layout";
import Banner from "./../../../components/course/Banner";
import Tab from "./../../../components/course/Tab";
export default {
  components: {
    LayoutHome,
    Banner,
    Tab,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
</style>