<template>
  <LayoutHome>
    <div class="bread-crumb">
      您当前所在的位置 ：新闻动态 > 新闻详情
    </div>
    <div class="news-detail">
      <div class="title">{{newInfo.title}}</div>
      <div class="uptime">{{handleTime(newInfo.publishTime, 'YYYY/MM/DD')}}</div>
      <div class="content">
        <div class="line"></div>
        <div :style="{display:newInfo.videoUrl ? 'block':'none'}" id="new-vedio-container"></div>
        <div v-html="newInfo.content"></div>
      </div>
    </div>
  </LayoutHome>
</template>
<script>
import LayoutHome from "./../../components/Layout";
import Time from './../../plugins/date';
export default {
  components: {
    LayoutHome,
  },
  data() {
    return {
      newInfo: [],
      id:this.$route.params.id,
    };
  },
  created() {
    this.getNewInfo();
  },
  methods: {
    play(url) {
      $("#new-vedio-container").Ableplayer(
        {
          id:+url,
          control: {
            //播放器按钮显示控制
            nextBtn: false, //下一节 默认false
            rateBtn: false, //速率 默认显示
            fullBtn: false, //全屏 默认显示
            volumeBtn: false, //音量 默认显示
            trackBtn: false, //字幕 默认显示
            definiBtn: false, //清晰度 默认显示

            bigPlayerBtn: false, //大播放按钮 默认显示
            playTime: false, //进度时间 默认显示
            errorBar: false, //错误提示 默认显示
          },
        },
        {
        }
      );
    },
    //而格式化时间
    handleTime(time, format, type) {
      if (type === 'getSingleDay') {
        return Time.getSingleDay(time);
      }
      return Time.getDay(time, format);
    },
    async getNewInfo() {
      const newInfo = await this.$get(`/news/findOne/${this.id}`);
      this.newInfo = newInfo;
    },
  },
  watch:{
    newInfo(n) {
      if (this.newInfo.videoUrl) {
        this.play(this.newInfo.videoUrl);
      }
    },
  }
};
</script>
<style scoped>
.bread-crumb {
  width:1200px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin: 78px auto 16px;
}
.news-detail{background:#fff;width:1200px;margin:0 auto;padding:38px 0 50px}
.news-detail .title{font-size:26px;line-height:37px;color:#2a2a2a;text-align:center}
.news-detail .uptime{font-size:14px;line-height:20px;color:#666;text-align:center;margin:14px 0 24px 0}
.content{width:800px;margin:0 auto}
.line{border-bottom:solid 1px #eee;margin-bottom:25px}
#new-vedio-container{
  width: 800px;
  height: 600px;
  margin: 20px auto;
}
</style>