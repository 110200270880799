<template>
  <LayoutHome>
    <!--Banner-->
    <div class="banner note" :style="{backgroundImage: 'url(' + setBackground + ')'}">
      <h3 class="main-title">{{setNewsName}}</h3>
      <h4 class="sub-title">{{setNewsDescription}}</h4>
    </div>
    <!--List-->
    <div class="news-wrapper">
      <div class="news-screen">
        <b>新闻分类：</b>
        <span @click="chooseClass('')" :style="{color:active === ''?'#fff':'#676767',backgroundColor:active === ''?themeColor:'#fff'}">全部</span>
        <span v-for="item in catalogList" :key="item.id" @click="chooseClass(item.id)"  :style="{color:active === item.id?'#fff':'#676767',backgroundColor:active === item.id?themeColor:'#fff'}">{{item.name}}</span>
      </div>
      <el-row>
        <div class="news-list">
          <div class="list-show" :span="6" v-for="(item,index) in newsList" :key="index">
              <div class="list-show-info" @click="toDetail(item.id)">
                <div class="info-img">
                  <img :src="item.coverAttachmentUrl ? item.coverAttachmentUrl : ''" class="image">
                </div>
                <div class="info-show">
                  <el-tooltip effect="dark" :content="item.title" placement="top-start">
                    <b>{{item.title}}</b>
                  </el-tooltip>
                  <em>{{handleTime(item.publishTime, 'YYYY/MM/DD')}}</em>
                </div>
              </div>
          </div>
        </div>
      </el-row>
      <div class="page">
        <span
          @click="handleCurrentChange(currentPage - 1)"
          class="prev"
          :class="{ disable: 1 === currentPage }"
          >上一页</span
        >
        <span
          @click="handleCurrentChange(i)"
          class="num"
          :style="{color:i === currentPage ? '#fff' : '#666',backgroundColor:i=== currentPage ? themeColor : '#fff'}"
          v-for="i in totalPages"
          :key="i"
          >{{ i }}</span
        >
        <span
          @click="handleCurrentChange(currentPage + 1)"
          class="next"
          :class="{ disable: currentPage === totalPages }"
          >下一页</span
        >
      </div>
    </div>
  </LayoutHome>
</template>
<script>
import LayoutHome from "./../../components/Layout";
import Time from './../../plugins/date';
import { getCookie,setCookie,removeCookie,getLoginUUId,getLoginUserId,getLoginRealName,getLoginUserName,getLoginUserUrl } from "../../plugins/cookie";
export default {
  components: {
    LayoutHome,
    newsList: [],
  },
  data() {
    return {
      formInline: {
        pageSize:4,
        pageNum:1
      },
      currentPage: 1,
      catalogId:'',
      catalogList: [],
      active:'',
      newsList: [],
      totalElements: 0,
      newsTotal:0,
      tmp:'',
      setNewsName:'',
      setNewsDescription:'',
      setBackground:'',
      setFindNewsId:'E7629B210443421886EB1DE7D590E203',
      totalPages:0
    };
  },
  computed:{
  themeColor(){
        return this.$store.state.themeColor;
      },
  },
  created() {
    this.getCatalog();
    this.getNewsList();
    this.getNewsSet();

  },
  methods: {
    async getSchoolEntity() {
      let school = await this.$get(`/admin/getSchoolByContext/${this.$route.params.context}`);
      return school;
    },
    async getNewsSet(){
      const newsDate = await this.$get("/school-news/findList/foreign", {foreign:"schoolId", value:(await this.getSchoolEntity()).id});
      this.setNewsName = newsDate[0].name;
      this.setNewsDescription = newsDate[0].description;
      this.setBackground = newsDate[0].backgroundAttachmentUrl;
    },
    toDetail(obj) {
      this.$router.push(`/${this.$route.params.context}/news-detail/${obj}`);
    },
    //新闻分类
    chooseClass(obj){
      this.active = obj;
      this.getNewsList();
    },
    async getCatalog() {
      const catalogList = await this.$get("/catalog/findPage", {
        pageSize: 100000,
        pageNum: 1,
      });
      this.catalogList = catalogList.content;
      this.totalElements = catalogList.totalElements;
    },
    async getNewsList() {
      const newsList = await this.$get("/news/findPage",
          {
            ...this.formInline,
            catalogId: this.active,
          },
      );
      this.newsList = newsList.content;
      this.newsTotal = newsList.totalElements;
      this.totalPages = newsList.totalPages;
      this.currentPage = this.formInline.pageNum;
    },
    //而格式化时间
    handleTime(time, format, type) {
      if (type === 'getSingleDay') {
        return Time.getSingleDay(time);
      }
      return Time.getDay(time, format);
    },
    //选择页数
    handleCurrentChange(currentPage) {
      if(currentPage === 0 || currentPage > this.totalPages)return;
      this.formInline.pageNum = currentPage;
      this.getNewsList()
    },
  }
};
</script>
<style lang="scss" scoped>
.banner {
  background-repeat: no-repeat;
  background-position: center bottom;
  height:270px;
  padding:94px 0 0;
}
.news-screen{line-height:20px;padding:20px 5px;border-bottom:#eee solid 1px;margin-bottom:10px;}
.news-screen b{display:inline-block;font-size: 14px;color:#333;}
.news-screen span{font-size: 14px;color:#333;font-weight:500;margin-right:10px;cursor: pointer;padding:5px 10px;border-radius:3px;}
.news-screen span:hover{background:#5676dc;color:#fff;}
.news-screen .active{background:#5676dc;color:#fff;}
.news-wrapper {
  width: 1200px;
  margin: 0 auto;
  border: solid 1px #eee;
  padding:10px 20px;
  position: relative;
  top: -52px;
  background: #fff;
  border-radius: 8px;
}

.main-title {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  line-height: 46px;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 5px 0;
}
.sub-title {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  line-height: 29px;
  text-align: center;
  margin: 0;
}
.news-list{display:flex;align-items: center;justify-content: space-between;flex-wrap: wrap;}
.list-show{width:50%;padding:10px 10px 0;}
.list-show-info{
  height:144px;
  border-radius:5px;
  padding:20px 0px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover{
    b{
      color: #5676dc;
    }
  }
}
.info-img{width:177px;height:100px;}
.info-img img{width:177px;height:100%;object-fit: cover;}
.info-show{width:100%;height:110px;padding:0 6px 0 26px;display:flex;align-content: space-between;flex-wrap: wrap;align-items:center;justify-content: flex-start;}
.info-show b{
  color:#333333;
  font-size:18px;
  font-weight:400;
  color: #333333;
  line-height:25px;
  width:100%;
  text-align:left;
  display:block;
}
.info-show em{
  width:100%;
  font-style:normal;
  font-weight: 400;
  color: #999999;
  line-height: 29px;
  display:block;
}
</style>
<style>
.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}
</style>