<!--首页-->
<template>
  <div class="header-wrap">
    <div class="nav-home" :style="{ backgroundColor: themeColor }">
      <div class="nav-container">
        <div>
          <span class="logo"><img :src="webInfo.logoAttachmentUrl" /></span>
          <span
            class="font"
            style="font-size: 22px; color: #fff; vertical-align: middle"
            >{{ webInfo.name }}</span
          >
        </div>
        <div style="width: auto; text-algin: right">
          <a  v-if="$route.params.context === 'hepec'" href="//hepeckcsz.zhihuishu.com" target="_blank"><span class="kcsz-btn">课程思政平台</span></a>
          <el-dropdown v-if="userInfo" @command="logout">
            <span
              class="el-dropdown-link"
              style="color: rgba(255, 255, 255, 0.85)"
            >
              <el-button class="reg-btn" type="primary"
                >{{ userInfo }}<i class="el-icon-arrow-down el-icon--right"></i
              ></el-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="['admin', 'teacher'].includes(role)"
                command="system"
                >管理后台</el-dropdown-item
              >
              <el-dropdown-item command="toZhihuishu"
                >在线学堂</el-dropdown-item
              >
              <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button class="reg-btn" v-else type="primary" @click="toLogin"
            >登录/注册</el-button
          >
          <el-button
            class="apply-file"
            v-if="quick.enabled === 'yes'"
            style="margin-left: 10px"
            :style="quick.style"
            @click="windowOpen(quick.url)"
            >{{ quick.text }}</el-button
          >
        </div>
      </div>
    </div>
    <div class="nav-wrapper">
      <div style="width: 1200px; margin: 0 auto">
        <router-link v-for="({ path, name }, i) in tabs" :to="path" :key="i">
          <span class="nav-item" :class="{ active: currentPath === path }">{{
            name
          }}</span>
        </router-link>
      </div>
    </div>
    <div style="width: 1200px; margin: 0 auto">
      <el-carousel>
        <el-carousel-item v-for="(item, index) in bannerlist" :key="index">
          <img
            :src="item.imageAttachmentUrl ? item.imageAttachmentUrl : ''"
            @click="bannerClick(item)"
            class="image"
          />
        </el-carousel-item>
      </el-carousel>
      <div class="card-list">
        <div class="card-course">
          <span class="title" :style="{ color: themeColor }">{{
            index.label1
          }}</span>
          <span>
            <span class="count">{{ numbers.one }}</span>
            <span class="unit">{{ index.unit1 }}</span>
          </span>
        </div>
        <div class="card-course">
          <span class="title" :style="{ color: themeColor }">{{
            index.label2
          }}</span>
          <span>
            <span class="count">{{ numbers.two }}</span>
            <span class="unit">{{ index.unit2 }}</span>
          </span>
        </div>
        <div class="card-course">
          <span class="title" :style="{ color: themeColor }">{{
            index.label3
          }}</span>
          <span>
            <span class="count">{{ numbers.three }}</span>
            <span class="unit">{{ index.unit3 }}</span>
          </span>
        </div>
        <div class="card-course">
          <span class="title" :style="{ color: themeColor }">{{
            index.label4
          }}</span>
          <span>
            <span class="count">{{ numbers.four }}</span>
            <span class="unit">{{ index.unit4 }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getCookie,
  getLoginRealName,
  getLoginUserId,
  removeCookie,
  getLoginUUId,
} from "../../plugins/cookie";
import axios from "axios";
import { copyFileSync } from "fs";

export default {
  inject: ["reload"],
  computed: {
    themeColor() {
      return this.$store.state.themeColor;
    },
    userInfo: function () {
      if (getCookie("CASLOGC")) {
        return getLoginRealName();
      }
      return "";
    },
    userId() {
      return getLoginUserId();
    },
    tabs() {
      let ret = [];
      // console.log(this.$store.state.tabs);
      let domain = window.location.hostname;
      this.showModules.forEach((ele) => {
        this.$store.state.tabs.forEach((element) => {
          if (element.menuId === ele.menuId) {
            let e = element;
            e.name = ele.menuName;
            if (!e.path.startsWith("/" + this.$route.params.context)) {
              e.path = "/" + this.$route.params.context + e.path;
            }
            ret.push(e);
          }
        });
      });
      return ret;
    },
    currentPath() {
      return this.$route.path;
    },
  },
  data() {
    return {
      carouselList: [],
      bannerlist: [],
      numbers: {
        one: 0,
        two: 0,
        three: 0,
        four: 0,
      },
      webInfo: {
        name: "",
        logoAttachmentUrl: "",
      },
      role: null,
      isAdmin: false,
      showModules: [],
      index: {},
      quick: {},
      teacherInfo: {}, //教师信息
    };
  },
  created() {
    let url = window.location.href;
    this.getWebInfo();
    this.getBannerList();
    this.initModule();
    this.initIsAdmin();
    this.initSchoolIndex();
    this.initQuick();
    if(this.userId){
      this.getRoleInfo();
    }
  },
  methods: {
    windowOpen(url) {
      if (!url.startsWith("http")) {
        url = "http://" + url;
      }
      window.open(url);
    },
    async getSchoolEntity() {
      return await this.$get(
        `/admin/getSchoolByContext/${this.$route.params.context}`
      );
    },
    async initSchoolIndex() {
      let res = await this.$get("/school-index/findList/foreign", {
        foreign: "schoolId",
        value: (await this.getSchoolEntity()).id,
      });
      this.index = res[0] || {};
      if (!this.index.number1) {
        this.index.number1 = "k1";
      }
      if (!this.index.number2) {
        this.index.number2 = "k2";
      }
      if (!this.index.number3) {
        this.index.number3 = "k3";
      }
      if (!this.index.number4) {
        this.index.number4 = "k10";
      }
      let param = { pageSize: 20, pageNum: 1, shoolId: this.index.schoolId };
      switch (this.index.number1) {
        case "k1":
          this.numbers.one =
            (await this.$get(`/admin/getUndergraduateCourseDtoPage`, param))
              .totalElements || 0;
          break;
        case "k2":
          this.numbers.one =
            (await this.$get(`/admin/getDeclarationCourseDtoPage`, param))
              .totalElements || 0;
          break;
        case "k3":
          this.numbers.one = await this.$get(`/course-online/findCount`, param);
          break;
        case "k4":
          this.numbers.one = await this.$get(
            `/course-mixture/findCount`,
            param
          );
          break;
        case "k5":
          this.numbers.one = await this.$get(
            `/course-offline/findCount`,
            param
          );
          break;
        case "k6":
          this.numbers.one = await this.$get(
            `/course-experiment/findCount`,
            param
          );
          break;
        case "k7":
          this.numbers.one = await this.$get(
            `/course-practice/findCount`,
            param
          );
          break;
        case "k8":
          this.numbers.one =
            (await this.$get(`/admin/getOthers1CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k9":
          this.numbers.one =
            (await this.$get(`/admin/getOthers2CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k10":
          this.numbers.one = await this.$get(`/policy/findCount`, param);
          break;
        case "k11":
          this.numbers.one = await this.$get(`/news/findCount`, param);
          break;
        case "k12":
          this.numbers.one = await this.$get(`/teacher/findCount`, param);
          break;
        case "k13":
          this.numbers.one =
            (await this.$get(`/admin/getOthers3CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k14":
          this.numbers.one =
            (await this.$get(`/admin/getOthers4CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k15":
          this.numbers.one =
            (await this.$get(`/admin/getOthers5CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k16":
          this.numbers.one =
            (await this.$get(`/admin/getOthers6CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k17":
          this.numbers.one =
            (await this.$get(`/admin/getOthers7CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k18":
          this.numbers.one =
            (await this.$get(`/admin/getOthers8CourseDtoPage`, param)
              .totalElements) || 0;
          break;
      }
      switch (this.index.number2) {
        case "k1":
          this.numbers.two =
            (await this.$get(`/admin/getUndergraduateCourseDtoPage`, param))
              .totalElements || 0;
          break;
        case "k2":
          this.numbers.two =
            (await this.$get(`/admin/getDeclarationCourseDtoPage`, param))
              .totalElements || 0;
          break;
        case "k3":
          this.numbers.two = await this.$get(`/course-online/findCount`, param);
          break;
        case "k4":
          this.numbers.two = await this.$get(
            `/course-mixture/findCount`,
            param
          );
          break;
        case "k5":
          this.numbers.two = await this.$get(
            `/course-offline/findCount`,
            param
          );
          break;
        case "k6":
          this.numbers.two = await this.$get(
            `/course-experiment/findCount`,
            param
          );
          break;
        case "k7":
          this.numbers.two = await this.$get(
            `/course-practice/findCount`,
            param
          );
          break;
        case "k8":
          this.numbers.two =
            (await this.$get(`/admin/getOthers1CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k9":
          this.numbers.two =
            (await this.$get(`/admin/getOthers2CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k10":
          this.numbers.two = await this.$get(`/policy/findCount`, param);
          break;
        case "k11":
          this.numbers.two = await this.$get(`/news/findCount`, param);
          break;
        case "k12":
          this.numbers.two = await this.$get(`/teacher/findCount`, param);
          break;
        case "k13":
          this.numbers.two =
            (await this.$get(`/admin/getOthers3CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k14":
          this.numbers.two =
            (await this.$get(`/admin/getOthers4CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k15":
          this.numbers.two =
            (await this.$get(`/admin/getOthers5CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k16":
          this.numbers.two =
            (await this.$get(`/admin/getOthers6CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k17":
          this.numbers.two =
            (await this.$get(`/admin/getOthers7CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k18":
          this.numbers.two =
            (await this.$get(`/admin/getOthers8CourseDtoPage`, param)
              .totalElements) || 0;
          break;
      }
      switch (this.index.number3) {
        case "k1":
          this.numbers.three =
            (await this.$get(`/admin/getUndergraduateCourseDtoPage`, param))
              .totalElements || 0;
          break;
        case "k2":
          this.numbers.three =
            (await this.$get(`/admin/getDeclarationCourseDtoPage`, param))
              .totalElements || 0;
          break;
        case "k3":
          this.numbers.three = await this.$get(
            `/course-online/findCount`,
            param
          );
          break;
        case "k4":
          this.numbers.three = await this.$get(
            `/course-mixture/findCount`,
            param
          );
          break;
        case "k5":
          this.numbers.three = await this.$get(
            `/course-offline/findCount`,
            param
          );
          break;
        case "k6":
          this.numbers.three = await this.$get(
            `/course-experiment/findCount`,
            param
          );
          break;
        case "k7":
          this.numbers.three = await this.$get(
            `/course-practice/findCount`,
            param
          );
          break;
        case "k8":
          this.numbers.three =
            (await this.$get(`/admin/getOthers1CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k9":
          this.numbers.three =
            (await this.$get(`/admin/getOthers2CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k10":
          this.numbers.three = await this.$get(`/policy/findCount`, param);
          break;
        case "k11":
          this.numbers.three = await this.$get(`/news/findCount`, param);
          break;
        case "k12":
          this.numbers.three = await this.$get(`/teacher/findCount`, param);
          break;
        case "k13":
          this.numbers.three =
            (await this.$get(`/admin/getOthers3CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k14":
          this.numbers.three =
            (await this.$get(`/admin/getOthers4CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k15":
          this.numbers.three =
            (await this.$get(`/admin/getOthers5CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k16":
          this.numbers.three =
            (await this.$get(`/admin/getOthers6CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k17":
          this.numbers.three =
            (await this.$get(`/admin/getOthers7CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k18":
          this.numbers.three =
            (await this.$get(`/admin/getOthers8CourseDtoPage`, param)
              .totalElements) || 0;
          break;
      }
      switch (this.index.number4) {
        case "k1":
          this.numbers.four =
            (await this.$get(`/admin/getUndergraduateCourseDtoPage`, param))
              .totalElements || 0;
          break;
        case "k2":
          this.numbers.four =
            (await this.$get(`/admin/getDeclarationCourseDtoPage`, param))
              .totalElements || 0;
          break;
        case "k3":
          this.numbers.four = await this.$get(
            `/course-online/findCount`,
            param
          );
          break;
        case "k4":
          this.numbers.four = await this.$get(
            `/course-mixture/findCount`,
            param
          );
          break;
        case "k5":
          this.numbers.four = await this.$get(
            `/course-offline/findCount`,
            param
          );
          break;
        case "k6":
          this.numbers.four = await this.$get(
            `/course-experiment/findCount`,
            param
          );
          break;
        case "k7":
          this.numbers.four = await this.$get(
            `/course-practice/findCount`,
            param
          );
          break;
        case "k8":
          this.numbers.four =
            (await this.$get(`/admin/getOthers1CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k9":
          this.numbers.four =
            (await this.$get(`/admin/getOthers2CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k10":
          this.numbers.four = await this.$get(`/policy/findCount`, param);
          break;
        case "k11":
          this.numbers.four = await this.$get(`/news/findCount`, param);
          break;
        case "k12":
          this.numbers.four = await this.$get(`/teacher/findCount`, param);
          break;
        case "k13":
          this.numbers.four =
            (await this.$get(`/admin/getOthers3CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k14":
          this.numbers.four =
            (await this.$get(`/admin/getOthers4CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k15":
          this.numbers.four =
            (await this.$get(`/admin/getOthers5CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k16":
          this.numbers.four =
            (await this.$get(`/admin/getOthers6CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k17":
          this.numbers.four =
            (await this.$get(`/admin/getOthers7CourseDtoPage`, param)
              .totalElements) || 0;
          break;
        case "k18":
          this.numbers.four =
            (await this.$get(`/admin/getOthers8CourseDtoPage`, param)
              .totalElements) || 0;
          break;
      }
    },
    async initQuick() {
      let res = await this.$get("/school-quick/findList/foreign", {
        foreign: "schoolId",
        value: (await this.getSchoolEntity()).id,
      });
      this.quick = res[0] || {};
      if (this.quick.color)
        this.quick.style = "backgroundColor:" + this.quick.color;
    },
    bannerClick({ link, url, text, id }) {
      switch (link) {
        case "url":
          window.open(url);
          break;
        case "text":
          window.open(`/#/${this.$route.params.context}/banner-detail/${id}`);
          break;
        default:
          break;
      }
    },
    async initModule() {
      let url =
        "https://aidedteachingdata.zhihuishu.com/aidedteachingData/firstUndergraduate/getPagePermission?schoolId=" +
        (await this.getWebInfo()).zhihuishuId;
      let res = await axios.get(url);
      this.showModules = res.data.rt.sort((a,b)=>a.sort-b.sort);
    },
    async initIsAdmin() {
      let url =
        "https://aidedteachingdata.zhihuishu.com/aidedteachingData/firstUndergraduate/getMenuPermission?schoolId=" +
        (await this.getWebInfo()).zhihuishuId +
        "&&uuid=" +
        getLoginUUId();
      let res = await axios.get(url);
      this.isAdmin =
        res.data.rt &&
        res.data.rt.fuMenuDtos &&
        res.data.rt.fuMenuDtos.length > 0;
    },
    async getRoleInfo() {
      const roleInfo = await this.$get("/admin/judgeRole");
      this.role = roleInfo;
    },
    toLogin() {
      let url = window.location.href;
      url = url.replace("#", "%23");
      window.location.href =
        "https://passport.zhihuishu.com/login?source=3&service=" + url;
    },
    async getWebInfo() {
      if (this.webInfo.id) {
        return this.webInfo;
      }
      this.webInfo = await this.$get(
        `/admin/getSchoolByContext/${this.$route.params.context}`
      );
      this.webInfo.color &&
        this.$store.commit("mergeToState", { themeColor: this.webInfo.color });
      return this.webInfo;
    },
    async logout(command) {
      switch (command) {
        case "system":
          window.open(
            `https://zygl.zhihuishu.com/#/${this.$route.params.context}/welcome`
          );
          break;
        case "toZhihuishu":
          window.open(
            "https://onlineh5.zhihuishu.com/onlineWeb.html#/teachIndex"
          );
          break;
        case "logout":
          removeCookie("CASLOGC", ".zhihuishu.com");
          removeCookie("CASTGC", ".zhihuishu.com");
          this.reload();
      }
    },
    async getBannerList() {
      const obj = await this.$get("/banner/findPage", {
        pageSize: 10,
        pageNum: 1,
        type: "index",
        sortType: "desc",
      });
      this.bannerlist = obj.content;
    },
    async getNumbers() {
      this.numbers = await this.$get("/web/getCourseNumber");
    },
  },
};
</script>    
<style lang="scss" scoped>
.header-wrap {
  // height: 575px;
  height: 542px;
  margin: 0 auto;
}
.logo {
  width: 274px;
}
.logo img {
  max-width: 274px;
  max-height: 46px;
  vertical-align: middle;
  margin-right: 20px;
}
.font {
  font-size: 22px;
  color: #fff;
}
.nav-home {
  height: 68px;
  background: #5676dc;
  .nav-container {
    width: 1200px;
    margin: 0 auto;
    padding: 9px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .reg-btn {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
    border-radius: 20px;
  }
}
.nav-wrapper {
  background-color: rgba(31, 65, 183, 0.5);
  margin-bottom: 33px;
  height: 44px;
}
.nav-item {
  display: inline-block;
  font-size: 16px;
  // font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 44px;
  margin-right: 36px;
}
.nav-item.active {
  border-bottom: rgba(255, 255, 255, 0.85) solid 1px;
}
.nav-item .icon {
  margin-right: 10px;
}
.slider-items {
  width: 100%;
  height: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.card-list {
  margin: 20px 0 33px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-list .card-course {
  box-sizing: border-box;
  width: 289px;
  height: 100;
  background: #ffffff;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  // padding: 37px 22px 48px 22px;
  padding: 26px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}
.card-list .card-course .title {
  font-size: 20px;
  color: #5676dc;
}
.card-list .card-course .count {
  font-size: 35px;
  font-weight: bold;
}
.card-list .card-course .unit {
  font-size: 16px;
  margin-left: 6px;
  color: #999;
}
.apply-file {
  background: #20ce80;
  border-radius: 20px;
  color: #fff;
  border: 0;
  padding: 10px 24px;
  font-weight: 600;
  cursor: pointer;
}
.kcsz-btn{
  cursor: pointer;
  display: inline-block;
  background-image: linear-gradient(140deg, #FFC652 0%, #E88F02 100%);
  font-size: 16px;
  line-height: 40px;
  padding: 0 16px;
  color: #fff;
  border-radius: 20px;
  border:0;
  margin-right: 16px;
}
</style>