import Dayjs from 'dayjs';
const Time =  {
  getDay: (time, format = 'YYYY/MM/DD') => {
    // 获取某天
    return Dayjs(time).format(format);
  },
  getDayNumber: (time) => {
    const day =  Dayjs(time).day();
    switch (day) {
      case 1:
        return '一'
        break;
      case 2:
        return '二'
        break;
      case 3:
        return '三'
        break;
      case 4:
        return '四'
        break;
      case 5:
        return '五'
        break;
      case 6:
        return '六'
        break;
      case 0:
        return '日'
        break;
      default:
        break;
    }
  },
  getSingleDay: (time) => {
    return Dayjs(time).format('DD');
  },
  getSingleMonth: (time) => {
    return Dayjs(this.time).format('MM');
  },
  getSingleYear: (time) => {
    return Dayjs(time).format('YYYY');
  },
  getYesterDay: (time, format) => {
    return Dayjs(time).subtract(1, 'day').format(format);
  },
};
export default Time;