<template>
  <LayoutHome>
    <div class="bread-crumb">
      您当前所在的位置 ：学校主页 > 资讯详情
    </div>
    <div class="news-detail">
      <div class="content">
        <div v-html="bannerInfo.content"></div>
      </div>
    </div>
  </LayoutHome>
</template>
<script>
import LayoutHome from "./../../components/Layout";
export default {
  components: {
    LayoutHome,
  },
  data() {
    return {
      bannerInfo: [],
      id:this.$route.params.id,
    };
  },
  created() {
    this.getBannerInfo();
  },
  methods: {
    async getBannerInfo() {
      const bannerInfo = await this.$get(`/banner/findOne/${this.id}`);
      this.bannerInfo = bannerInfo;
    },
  }
};
</script>
<style scoped>
.bread-crumb {
  width:1200px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin: 78px auto 16px;
}
.news-detail{background:#fff;width:1200px;margin:0 auto;padding:38px 0 50px}
.news-detail .title{font-size:26px;line-height:37px;color:#2a2a2a;text-align:center}
.news-detail .uptime{font-size:14px;line-height:20px;color:#666;text-align:center;margin:14px 0 24px 0}
.content{width:800px;margin:0 auto}
.line{border-bottom:solid 1px #eee;margin-bottom:25px}
</style>