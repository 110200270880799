<template>
  <div class="course-wrapper">
    <div class="course-title">课程建设与应用</div>
    <div class="content-wrapper">
      <div class="content">
        <div v-for="(item,i) in contentList" :key="i">
          <div class="cont-title"><span class="circle"></span>{{item.title}}</div>
          <div class="cont-intro" v-html="courseData[item.key] || '暂无数据'"></div>
        </div>
      </div>
      <div class="course-menu">
        <ul class="menu">
          <li class="menu-item active">课程应用</li>
          <li class="menu-item">课程建设计划</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.getCourseData();
  },
  computed: {
    catalog() {
      return this.$route.params.catalog;
    },
    courseId() {
      return this.$route.params.id;
    },
    contentList() {
      switch (this.catalog) {
        case "experiment":
          return [
            { title: "项目描述", key: "description" },
            { title: "网络要求", key: "network" },
            { title: "技术架构", key: "architecture" },
            { title: "项目特色", key: "feature" },
            { title: "服务计划", key: "plan" },
          ];
        default:
          return [
            { title: "课程目标", key: "goal" },
            { title: "建设及应用", key: "apply" },
            { title: "特色与创新", key: "feature" },
            { title: "建设计划", key: "construction" },
          ];
      }
    },
  },
  data(){
    return {
      courseData:{}
    }
  },
  methods: {
    async getCourseData() {
      let courseData = await this.$get(`/web/findDetailByCourseIdType`, {
        id: this.courseId,
        type: this.catalog,
      });
      this.courseData = courseData;
    },
  },
};
</script>
<style scoped>
.course-wrapper {
  width: 1200px;
  min-height: 300px;
  margin: 0 auto 40px;
  padding: 0 40px;
  background: #ffffff;
  border-radius: 12px;
}
.course-title {
  margin: 0 -40px;
  padding: 0 40px;
  height: 90px;
  font-size: 24px;
  font-weight: 600;
  color: #2a2a2a;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: #eee solid 1px;
}
.course-title span {
  font-size: 12px;
  line-height: 18px;
  color: #777;
  margin-left: 8px;
  font-weight: normal;
  padding: 10px 0 0;
}

.content-wrapper {
  clear: both;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0 0;
}
.content {
  width: 900px;
}
.cont-title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2a2a2a;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 10px;
}
.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 12px;
  background-color: #3d84ff;
  display: inline-block;
}
.cont-intro {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  line-height: 28px;
  padding: 0 0 20px 20px;
}
/*右侧滚动锚点*/
.course-menu {
  width: 150px;
}
.menu {
  list-style: none;
  border-left: solid 1px #eee;
  padding-left: 0;
}
.menu-item {
  margin-bottom: 15px;
  color: #777;
  padding-left: 22px;
  font-size: 14px;
  line-height: 24px;
  border-left: solid 3px transparent;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.menu-item.active {
  border-left-color: #3d84ff;
  color: #2a2a2a;
}
</style>