<template>
  <div class="banner">
    <div class="bannerShow">
      <div class="bannerFont">
        <div class="main-title">{{ courseData.courseName }}</div>
        <div class="sub-title"># {{typeDescription}}</div>
      </div>
      <a v-if="courseData.url" :href="courseData.url" target="_blank" :style="{backgroundColor:themeColor}" class="banner-study">去学习</a>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.getCourseData();
  },
  computed: {
    themeColor(){
      return this.$store.state.themeColor;
    },
    userInfo: function () {
      return this.$store.state.userInfo;
    },
    catalog() {
      return this.$route.params.catalog;
    },
    typeDescription() {
      const list = {
        online: "在线课程",
        mixture: "混合式课程",
        offline: "线下课程",
        experiment: "虚拟仿真实验",
        practice: "社会实践课程",
      };
      return list[this.catalog] || '未知'
    },
  },
  data() {
    return {
      courseData: {},
      courseId: this.$route.params.id,
    };
  },
  methods: {
    async getCourseData() {
      let courseData = await this.$get(`/web/findBaseByCourseIdType`, {
        id: this.courseId,
        type: this.catalog,
      });
      this.courseData = courseData;
      this.courseId = courseData.id;
    },
  },
};
</script>
<style scoped>
.banner {
  height: 270px;
  padding: 0;
  background: url(~../../assets/images/show_bg.png) no-repeat center bottom;
  background-size: cover;
  overflow: hidden;
}
.bannerShow {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.bannerFont {
  clear: both;
  padding: 90px 0 0;
}
.main-title {
  font-size: 36px;
  color: #fff;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 50px;
  padding: 20px 0;
}
.sub-title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  line-height: 25px;
  padding: 0;
}
.banner-study {
  width: 152px;
  height: 44px;
  background: #ffdeb2;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  line-height: 44px;
  text-align: center;
  margin-bottom: 20px;
}
</style>