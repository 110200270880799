<!--首页-->
<template>
  <div>
    <el-container>
      <el-header class="header" :style="{backgroundImage:`url(${indexData.background1AttachmentUrl} )`}">
        <Header />
      </el-header>
      <el-main class="main-wrap">
        <slot></slot>
      </el-main>
    </el-container>
    <Footer />
  </div>
</template>
<script>
import Header from "./HeaderHome";
import Main from "./../Main";
import Footer from "./../Footer";
import { getCookie,setCookie,removeCookie,getLoginUUId,getLoginUserId,getLoginRealName,getLoginUserName,getLoginUserUrl } from "../../plugins/cookie";
export default {
  name: "app",
  data(){
    return {
      indexData:{}
    }
  },
  created() {
    console.log(this.$route);
    console.log(this.$route.params.context);
    console.log(window.location);
    this.getIndexData();
    let domain = window.location.hostname;
    if (domain === 'localhost' || domain === '127.0.0.1' || domain === '103.21.142.38') {
      let caslogc = null;
      caslogc = '{"realName":"18516563032","myuniRole":0,"myinstRole":0,"userId":806288545,"headPic":"https://image.zhihuishu.com/zhs/ablecommons/demo/201804/0bab226a965c4e4e82b791c3a66a75f6_s3.jpg","uuid":"dlQ6pP0q","mycuRole":0,"username":"1d857a46c5d94c19ac137256dc1075cc"}';
      caslogc = '{"realName":"18061741762","myuniRole":1,"myinstRole":0,"userId":170730911,"headPic":"https://image.zhihuishu.com/zhs/ablecommons/demo/201804/dacc8bdfb6c7492ca32dfe37441842f9_s3.jpg","uuid":"VxxxyN5A","mycuRole":0,"username":"145f0291d717403e9a1d9202fc91a4cd"}';
      caslogc = '%7B%22realName%22%3A%22%E6%B4%B2%E5%96%B5%22%2C%22myuniRole%22%3A1%2C%22myinstRole%22%3A0%2C%22userId%22%3A187494685%2C%22headPic%22%3A%22https%3A%2F%2Fimage.zhihuishu.com%2Fzhs%2Fablecommons%2Fdemo%2F201804%2Fdacc8bdfb6c7492ca32dfe37441842f9_s3.jpg%22%2C%22uuid%22%3A%22dDQoQOYw%22%2C%22mycuRole%22%3A0%2C%22username%22%3A%228a17c733acc64e9aab2b3d21d3313eab%22%7D';
      let castgc = 'TGT-38061-O7q9BAQKIdREyU7aGJEbfQytvOaWzouFv3gbYJfRtQteKSfKve-passport.zhihuishu.com';
      setCookie("CASLOGC", caslogc, 1);
      setCookie("CASTGC", castgc, 1);
    }
  },
  components: {
    Header,
    Main,
    Footer,
  },
  methods: {
    async getSchoolEntity() {
      let school = await this.$get(`/admin/getSchoolByContext/${this.$route.params.context}`);
      document.title = school.schoolName;
      return school;
    },
    async getIndexData() {
      const res = await this.$get("/school-index/findList/foreign", {
        foreign: "schoolId",
        value: (await this.getSchoolEntity()).id,
      });
      this.indexData = res[0] || {};
    },
  },
};
</script>
<style scoped>
.header {
  /* height: 575px !important; */
  height: 542px !important;
  background-size: 100% 521px;
  background-repeat: no-repeat;
  padding: 0;
}
.main-wrap{background-color: #fff; padding: 0 0 18px 0;margin-top: 33px;}
</style>